import React from 'react';
import './App.css';

const CircleGenerator = ({ color, width, height, gradient, gradientColor1, gradientColor2 }) => {
  const backgroundColor = gradient
    ? `linear-gradient(${gradientColor1}, ${gradientColor2})`
    : color;

  return (
    <div className="circle-container">
        <div
            className="circle"
            style={{
                '--color': backgroundColor,
                '--width': `${width}px`,
                '--height': `${height}px`
            }}>
        </div>
    </div>
  );
};

export default CircleGenerator;
