import React, { useState } from 'react';
import './App.css';
import Navigation from './Navigation.js'
import CircleGenerator from './CircleGenerator';
import GeneratedCode from './GeneratedCode';
import Footer from './Footer.js';


function App() {
  const [color, setColor] = useState('#000000');
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);
  const [gradient, setGradient] = useState(false);
  const [gradientColor1, setGradientColor1] = useState('#ff0000');
  const [gradientColor2, setGradientColor2] = useState('#0000ff');
  const [showCode, setShowCode] = useState(false);

  const toggleCodeVisibility = () => {
    setShowCode(!showCode);
  };

  const toggleGradient = () => {
    setGradient(!gradient);
  };

  return (
    <div>
       <header>
       <img src="/img/css-circle-generator-logo.png" className="logo" alt="CSS Circle Generator Logo" />
        <a href="/" className="logo-title">CSS Circle Generator </a>
          <nav className="menu">
            <Navigation />
          </nav>
      </header>
        <div className="container">
            <h1>CSS Circle Generator</h1>
            <h2>Create a circle with CSS or use as a circle gradient generator</h2>
            <div className="controls">
            <label>
                Choose a Color:
                <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
            </label>
            <h3 className="controls-title">Select a Width and Height for the Circle </h3>
            <br />
            <label>
                Width (px):
                <input type="number" value={width} onChange={(e) => setWidth(Number(e.target.value))} />
            </label>
            <label>
                Height (px):
                <input type="number" value={height} onChange={(e) => setHeight(Number(e.target.value))} />
            </label>
            <h3 className="controls-title">Select a Color Gradient (Optional) </h3>
            <br /> 
            <label>
                Add Color Gradient:
                <input type="checkbox" checked={gradient} onChange={toggleGradient} />
            </label>
            {gradient && (
                <>
                <label>
                    Gradient Color 1:
                    <input type="color" value={gradientColor1} onChange={(e) => setGradientColor1(e.target.value)} />
                </label>
                <label>
                    Gradient Color 2:
                    <input type="color" value={gradientColor2} onChange={(e) => setGradientColor2(e.target.value)} />
                </label>
                </>
            )}
            <button className="button" onClick={toggleCodeVisibility}>
                {showCode ? 'Hide Code' : 'Show Code'}
            </button>
            </div>
            <CircleGenerator
            color={color}
            width={width}
            height={height}
            gradient={gradient}
            gradientColor1={gradientColor1}
            gradientColor2={gradientColor2}
            />
            {showCode && (
            <GeneratedCode
                color={color}
                width={width}
                height={height}
                gradient={gradient}
                gradientColor1={gradientColor1}
                gradientColor2={gradientColor2}
            />
            )}
        </div>
        <Footer />
      </div>
  );
}

export default App;
