import React from 'react';

const GeneratedCode = ({ color, width, height, gradient, gradientColor1, gradientColor2 }) => {
  const backgroundColor = gradient
    ? `linear-gradient(${gradientColor1}, ${gradientColor2})`
    : color;

  const htmlCode = `
<div class="circle"></div>
`;

  const cssCode = `
.circle {
  width: ${width}px;
  height: ${height}px;
  background: ${backgroundColor};
  border-radius: 50%;
}
`;

  return (
    <div className="generated-code">
      <h3>Copy the HTML Code Below</h3>
      <pre>
        <code>{htmlCode}</code>
      </pre>
      <h3>Copy the CSS Code Below</h3>
      <pre>
        <code>{cssCode}</code>
      </pre>
    </div>
  );
};

export default GeneratedCode;