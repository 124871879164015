import React, { Component } from 'react';
import Navigation from './Navigation.js'
import Footer from './Footer.js'
import './App.css';


class About extends Component {
  
  render() {
    return (
      <div>
        <header>
        <img src="/img/css-circle-generator-logo.png" className="logo" alt="CSS Circle Generator Logo" />
        <a href="/" className="logo-title">CSS Circle Generator </a>
          <nav className="menu">
              <Navigation />
          </nav>
      </header>
         <div className="section">
          <h1>About</h1>
          <div>

            <p>We are a passionate team that aims to build easy to use tools that benefit others.
                <br /> CSS Circle Generator was created by developers for developers and non technical users
                that want to avoid the repetitive work of creating a circle using CSS.
            </p>

            <p>This <b>CSS Circle Generator</b> is a tool that allows you to make a circle in seconds.</p>

            <p>You can customize the circle according to your needs and even add a color gradient.
            See a live preview as you make changes and copy the generated HTML and CSS code for your project.</p>
            
            <h2>Click below to see our other generators and tools</h2>

            <p><b>Progress Bar Generator</b> - <a href="https://progressbargenerator.com/" target="_blank">progressbargenerator.com</a> </p>

            <p><b>Hamburger Menu Generator</b> - <a href="https://hamburgermenugenerator.com/" target="_blank">hamburgermenugenerator.com</a> </p>
            
            <p><b>REM to PX Converter</b> - <a href="https://remtopxconverter.com/" target="_blank">remtopxconverter.com</a> </p>

        

            <h2>How to Use the CSS Circle Generator</h2>

            <p><b>Step 1: </b>Choose a Color
            <br></br><br></br> Change the color of the circle by selecting a RGB value, 
            HEX value, or using the color wheel.</p>

            <p><b>Step 2:</b> Select a Width and Height for the Circle
            <br></br> <br></br>The width is a value that is a pixels (px) and is the width of the circle.
            <br></br> The height is a value that is in pixels (px) and is the height of the circle. </p>
            
            <p><b>Step 3: </b>Add a Color Gradient
            <br></br><br></br> Add two colors together and form a gradient.</p>

             <p><b>Step 4:</b> Copy the Generated Code <br></br><br></br>
             Click the "Show Code" to see a section where the HTML and CSS code is generated. <br />Copy the
             code and use the circle for your project.
              </p> 

          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;